<template>
  <div>
        <head-layout :head-btn-options="headBtnOptions"
        :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.sys.menu.title.editHeadTitle') : $t('cip.plat.sys.menu.title.addHeadTitle')"
          @head-save-back="headSave('back')" @head-save="headSave('save')" @head-cancel="headCancel">
        </head-layout>
        <!-- <form-layout ref="formLayout" icon="" :head-title="headtitle" page-type="self" :option="option"
      :btn-options="btnOptions" @form-btn-save-click="save" :dataForm="form"></form-layout>-->
        <form-layout :column="column" :dataForm.sync="dataObj" ref="formLayout"></form-layout>
    </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import { update, add, getMenuTree, getMenu} from "@/api/system/menu";
import iconList from "@/config/iconList";
import {mapGetters} from "vuex";
export default {
  components: { FormLayout, HeadLayout },
  computed: {
    ...mapGetters(['language'])
  },
  data() {
    let validateName = (rule, value, callback) => {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
          callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
        }
        if (val2.length > 100) {
          callback(new Error(this.$t('cip.cmn.rule.nameLength10Warning')));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.plat.sys.menu.field.name")+this.$t('cip.cmn.rule.noEmptyWarning')));
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0,1)==" " || val2.substring(val2.length-1)==" ") {
          callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
        }
        if (val2.length > 100) {
          callback(new Error(this.$t('cip.cmn.rule.nameLength10Warning')));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.plat.sys.menu.field.alias")+this.$t('cip.cmn.rule.noEmptyWarning')));
      }
    };
    return {
      parentId: 0,
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
      {
        label: this.$t('cip.cmn.btn.saveBackBtn'),
        emit: "head-save-back",
        type: "button",
        icon: ""
      }, {
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: "head-cancel",
        type: "button",
        icon: ""
      }],
      dataObj: {
      },
      column: [
        {
          label: this.$t("cip.plat.sys.menu.field.name"),
          prop: "name",
          search: true,
          isGlobal:true, //是否支持国际化
          rules: [{ required: true, validator: validateName, trigger: ["blur","change"] }]
        },
        {
          label: this.$t("cip.plat.sys.menu.field.path"),
          prop: "path",
          maxlength: 500,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.menu.field.path"),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t("cip.plat.sys.menu.field.parentId"),
          prop: "parentId",
          type: "tree",
          dicData: [],
          hide: true,
          addDisabled: false,
          props: {
            label: "title"
          },
          rules: [
            {
              required: false,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.menu.field.parentId"),
              trigger: "click"
            }
          ],
          change:({value}) => {
            this.getparentId(value)
          },
        },
        {
          label: this.$t("cip.plat.sys.menu.field.source"),
          prop: "source",
          type: "icon",
          slot: true,
          iconList: iconList,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.menu.field.source"),
              trigger: "click"
            }
          ]
        },
        {
          label: this.$t("cip.plat.sys.menu.field.platform"),
          type: "select",
          dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          display:false,
          dataType: "number",
          value: 1,
          disabled:true,
          prop: "platform",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.menu.field.platform"),
            trigger: "blur"
          }],
        },
        {
          label: this.$t("cip.plat.sys.menu.field.code"),
          prop: "code",
          search: true,
          maxlength:50,
          showWordLimit: true,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.sys.menu.field.code"),
              trigger: "blur"
            },
          ]
        },
        {
          label: this.$t("cip.plat.sys.menu.field.category"),
          prop: "category",
          type: "select",
          dataType: "number",
          disabled: ['edit'].includes(this.type),
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict/dictionary?code=menu_category",
          hide: true,
          value:1,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.menu.field.category"),
              trigger: "blur"
            }
          ]
        },
        {
          label: this.$t("cip.plat.sys.menu.field.alias"),
          prop: "alias",
          search: true,
          isGlobal:true, //是否支持国际化
          rules: [{ required: true, validator: validatePass, trigger: ["blur","change"] }]
        },
        {
          label: this.$t("cip.plat.sys.menu.field.isOpen"),
          prop: "isOpen",
          type: "select",
          disabled: false,
          // dicData: [
          //   {
          //     label: this.$t("cip.plat.sys.menu.field.no"),
          //     value: 1
          //   },
          //   {
          //     label: this.$t("cip.plat.sys.menu.field.yes"),
          //     value: 2
          //   }
          // ],
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          value: 1,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.menu.field.isOpen"),
              trigger: "blur"
            }
          ]
        },
        {
          label: this.$t("cip.plat.sys.menu.field.status"),
          prop: "status",
          type: "radio",
          // dicData: [
          //   {
          //     label: this.$t("cip.plat.sys.menu.field.yes"),
          //     value: 0
          //   },
          //   {
          //     label: this.$t("cip.plat.sys.menu.field.no"),
          //     value: 1
          //   }
          // ],
          dataType: 'number',
          value: 0,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          hide: true
        },
        {
          label: '是否需要单点',
          prop: "isSingleSignOn",
          type: "radio",
          dataType: 'number',
          value: 1,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          hide: true
        },
        {
          label: this.$t("cip.plat.sys.menu.field.sort"),
          prop: "sort",
          type: "number",
          minRows:0,
          maxRows:1000000000,
          rules: [
            {
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.sys.menu.field.sort`),
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sys.menu.field.isOnline"),
          prop: "isOnline",
          type: "select",
          labelWidth:120,
          disabled: false,
          // dicData: [
          //   {
          //     label: this.$t("cip.plat.sys.menu.field.no"),
          //     value: "1"
          //   },
          //   {
          //     label: this.$t("cip.plat.sys.menu.field.yes"),
          //     value: "2"
          //   }
          // ],
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          value: "0",
        },
        {
          label: '路由参数',
          prop: "params",
          hide: true
        },
        {
          label: this.$t("cip.plat.sys.menu.field.remark"),
          prop: "remark",
          type: "textarea",
          maxlength:500,
          span:24,
          showWordLimit: true,
          minRows: 3,
          hide: true
        }
      ]
    }
  },
  created() {
    console.log(this.$route.query)
    this.initData()
    if (this.$route.query.type == 'edit') {
      // console.log(decodeURIComponent(this.$route.query.data))
      // this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
      // const column = this.findObject(this.optionParent.column, "code");
      // column.display = false
      getMenu(this.$route.query.id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    } else if (this.$route.query.type == 'addChild') {
      this.parentId = this.$route.query.id;
      const column = this.findObject(this.column, "parentId");
      column.value = this.$route.query.id;
      column.addDisabled = true;
    }
  },
  mounted() {},

  methods: {
    getparentId(value){
      if (!value || value==0) {
        this.findObject(this.column, "category").disabled = true;
        this.dataObj.category=1;
      }else {
        this.findObject(this.column, "category").disabled = false;
      }
    },
    headSave(type) {
      this.dataObj.path = this.dataObj.path.trim()
      this.dataObj.code = this.dataObj.code.trim()
      if (this.dataObj.parentId === this.dataObj.id) {
        return this.$message.error(this.$t("cip.cmn.msg.error.nodeError"))
      }
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading()
          add(this.dataObj).then((res) => {
            if (type == 'back') {
              this.close()
            }
            this.dataObj = res.data.data
            this.dataObj.path = res.data.data.path.replace(/&amp;/g, "&")
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.isShow = false
            //
          }, error => {
            window.console.log(error);

          }).finally(()=>{
            this.$refs.formLayout.$refs.form.allDisabled = false
            this.$loading().close()
          })
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }


      })

    },
    initData() {
      getMenuTree().then(res => {
        const column = this.findObject(this.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
